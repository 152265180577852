
  import TitleActions from "@/components/shared/TitleActions.vue"
  import { defineComponent, ref } from "vue"

  export default defineComponent({
    name: "dashboard",
    components: { TitleActions},
    setup() {
      return {
      }
    }
  })
